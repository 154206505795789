import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 456.000000 410.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,410.000000) scale(0.100000,-0.100000)">
		<path d="M40 2055 l0 -2045 2250 0 2250 0 0 2045 0 2045 -2250 0 -2250 0 0
-2045z m2304 729 c13 -5 16 -23 15 -98 -2 -187 -24 -242 -57 -142 -10 28 -28
58 -43 69 -44 32 -133 47 -286 47 l-143 0 0 -281 0 -281 30 6 c17 3 69 24 116
46 47 22 87 40 90 40 2 0 37 15 77 34 40 18 99 46 132 61 33 16 112 49 175 74
63 26 121 50 128 54 25 16 9 265 -19 293 -9 8 -47 20 -85 25 -38 6 -71 13 -73
15 -2 2 2 10 9 18 10 13 58 15 315 16 269 0 312 -3 380 -20 122 -31 191 -81
231 -165 13 -27 24 -60 24 -72 0 -15 7 -24 23 -27 170 -35 278 -72 435 -147
83 -39 121 -62 116 -70 -5 -9 -10 -7 -19 4 -7 10 -22 17 -33 17 -11 0 -63 11
-114 25 -137 37 -291 65 -356 65 l-57 0 -28 -60 c-17 -36 -52 -85 -87 -121
-33 -34 -58 -65 -55 -70 3 -4 34 -22 68 -38 65 -31 165 -108 166 -127 1 -6 9
-18 18 -25 9 -8 29 -42 45 -76 24 -54 28 -75 28 -156 0 -85 -3 -98 -35 -165
-65 -136 -178 -217 -345 -246 -70 -13 -137 -14 -385 -8 -274 6 -300 8 -303 25
-3 14 4 17 33 17 48 0 71 14 91 52 14 28 17 75 18 333 l1 300 -22 -2 c-13 -2
-121 -51 -240 -111 -182 -90 -367 -174 -469 -212 -29 -11 -32 -43 -18 -189 14
-150 18 -156 123 -171 82 -11 93 -16 71 -30 -19 -12 -561 -1 -574 11 -6 5 1 9
17 9 15 0 28 -1 30 -2 1 -1 13 4 25 11 36 19 50 66 58 189 8 135 20 124 -143
131 -43 2 -53 5 -38 11 11 5 38 9 60 9 50 1 187 33 285 67 79 28 386 174 432
205 14 11 31 19 38 19 7 0 24 8 38 18 15 10 74 39 132 64 174 77 366 118 549
118 l114 0 33 35 c31 34 64 114 64 157 0 28 -23 32 -108 18 -76 -12 -288 -64
-327 -80 -11 -4 -49 -18 -85 -30 -74 -26 -108 -40 -198 -81 -35 -16 -65 -29
-67 -29 -3 0 -34 -13 -70 -30 -36 -16 -68 -30 -70 -30 -2 0 -28 -11 -57 -25
-181 -84 -446 -173 -593 -200 -39 -7 -117 -16 -174 -20 -103 -7 -104 -7 -98
14 3 14 0 21 -9 21 -7 0 -11 -6 -8 -14 6 -17 -11 -30 -27 -20 -6 3 -63 6 -126
5 -126 -2 -160 4 -333 56 -116 36 -348 146 -357 170 -3 7 -14 13 -24 13 -10 0
-19 5 -19 10 0 6 9 10 20 10 11 0 20 -4 20 -9 0 -5 10 -11 23 -14 12 -3 65
-22 117 -42 173 -66 366 -110 568 -129 17 -1 35 -6 39 -9 3 -4 12 -2 18 3 7 6
53 15 101 21 88 9 161 25 239 51 l40 13 3 324 c2 253 -1 326 -10 333 -7 4
-102 8 -210 8 -125 0 -198 4 -198 10 0 6 18 10 40 10 22 0 40 5 40 10 0 6 -18
10 -41 10 -35 0 -41 -3 -50 -29 -6 -16 -21 -36 -33 -44 -12 -8 -33 -36 -47
-61 -25 -48 -46 -62 -35 -23 4 12 11 51 16 86 4 35 13 68 19 74 6 6 11 24 11
39 0 23 4 28 24 28 13 0 30 -9 37 -20 13 -19 20 -19 134 -11 66 6 293 13 505
16 212 4 412 11 445 15 84 10 91 11 109 4z m-396 -1536 c2 -29 7 -35 21 -31
25 6 51 -23 51 -58 0 -34 -34 -73 -57 -65 -8 3 -21 6 -29 6 -11 0 -14 17 -14
79 0 54 -4 81 -12 84 -10 4 -9 8 2 16 24 17 35 7 38 -31z m696 26 c3 -9 6 -27
6 -40 0 -20 5 -24 29 -24 31 0 41 -14 41 -61 0 -36 -34 -60 -72 -52 -28 5 -28
6 -28 83 0 53 -4 80 -12 83 -10 4 -9 8 2 16 20 15 27 14 34 -5z m-1014 -73 c0
-44 3 -86 6 -95 5 -12 0 -16 -19 -16 -14 0 -29 5 -32 10 -3 6 -1 10 4 10 7 0
11 29 11 75 0 43 -4 75 -10 75 -5 0 -10 5 -10 10 0 6 11 10 25 10 25 0 25 -1
25 -79z m260 1 c0 -47 5 -83 12 -90 13 -13 9 -14 -61 -14 -38 -1 -61 22 -61
59 0 28 33 63 60 63 21 0 28 24 10 35 -18 11 -10 25 15 25 25 0 25 -1 25 -78z
m-451 11 c14 -10 14 -12 -1 -18 -13 -5 -18 -18 -18 -46 0 -21 5 -39 10 -39 6
0 10 -4 10 -10 0 -20 -41 -10 -49 13 -14 38 -22 107 -12 107 5 0 13 10 17 22
6 20 7 21 17 2 6 -10 18 -24 26 -31z m871 22 c0 -8 7 -15 15 -15 8 0 15 -7 15
-15 0 -8 -6 -14 -12 -12 -21 4 -23 -65 -3 -79 15 -11 14 -13 -5 -19 -28 -9
-31 -5 -36 47 -2 24 -9 45 -14 46 -16 6 -12 32 5 32 8 0 15 7 15 15 0 8 5 15
10 15 6 0 10 -7 10 -15z m-756 -37 c32 -46 11 -100 -39 -100 -31 0 -55 24 -55
57 0 31 28 65 55 65 13 0 31 -10 39 -22z m170 12 c22 -8 26 -16 26 -48 0 -21
5 -43 12 -50 9 -9 9 -12 -2 -12 -8 -1 -32 -3 -52 -6 -31 -5 -38 -2 -49 17 -10
20 -9 25 11 41 12 10 28 18 36 18 8 0 14 5 14 10 0 13 -27 13 -36 -1 -4 -8
-10 -7 -18 1 -8 8 -6 15 8 26 23 17 19 16 50 4z m400 -6 c9 -8 16 -24 16 -35
0 -16 -7 -19 -40 -19 -22 0 -40 -4 -40 -10 0 -14 31 -28 51 -23 11 3 19 0 19
-6 0 -15 -63 -14 -78 1 -17 17 -15 74 4 92 8 9 24 16 34 16 10 0 26 -7 34 -16z
m111 3 c10 -8 15 -19 11 -26 -6 -9 -11 -9 -22 -1 -7 7 -19 10 -26 8 -7 -3 2
-13 20 -23 51 -30 34 -83 -22 -69 -33 8 -42 22 -20 31 10 4 19 2 22 -5 5 -17
32 -15 32 2 0 8 -13 19 -30 25 -34 11 -41 44 -12 60 22 14 25 13 47 -2z m189
0 c11 -8 17 -20 14 -27 -3 -10 -7 -9 -19 3 -9 8 -21 12 -28 8 -9 -6 -1 -15 23
-29 40 -24 43 -33 20 -56 -25 -25 -74 -14 -74 16 0 13 28 9 32 -4 4 -12 28 -3
28 11 0 4 -11 13 -25 19 -34 16 -42 40 -19 57 24 18 26 18 48 2z m80 -18 c3
-17 6 -43 6 -56 0 -19 4 -24 16 -19 9 3 18 6 20 6 10 0 3 69 -8 73 -10 4 -9 8
2 16 26 18 35 6 38 -47 2 -29 7 -52 13 -52 5 0 9 -4 9 -10 0 -12 -91 -11 -107
2 -7 6 -13 28 -13 49 0 21 -5 41 -10 44 -13 8 -3 25 14 25 8 0 16 -14 20 -31z
m374 19 c20 -20 14 -28 -20 -26 -28 3 -34 -1 -36 -20 -5 -32 13 -50 46 -45 26
4 26 4 8 -11 -19 -15 -50 -14 -68 3 -4 4 -10 21 -14 38 -10 51 50 95 84 61z
m116 -1 c20 -15 25 -70 7 -91 -33 -39 -91 -16 -91 36 0 55 44 84 84 55z m144
-1 c7 -4 20 -2 31 4 28 15 51 -12 51 -60 0 -21 5 -40 13 -43 8 -3 6 -7 -5 -12
-33 -13 -47 0 -38 38 9 40 -1 63 -24 54 -20 -8 -23 -77 -3 -78 6 0 1 -5 -12
-10 -31 -12 -36 -6 -32 40 3 44 -15 63 -34 37 -14 -18 -9 -56 9 -68 11 -8 10
-9 -32 -13 -25 -3 -38 10 -22 20 17 11 12 72 -7 79 -13 5 -14 9 -5 15 15 9 94
7 110 -3z m-370 -98 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z"/>
<path d="M2848 2679 c-26 -14 -27 -176 -2 -186 16 -6 217 13 257 24 9 2 17 12
17 22 0 28 -79 112 -125 132 -44 20 -120 24 -147 8z"/>
<path d="M2843 2058 c-4 -7 -9 -135 -10 -283 -3 -307 0 -321 72 -362 32 -18
56 -23 118 -23 85 0 125 12 175 55 55 46 72 92 72 193 0 138 -42 242 -132 328
-71 68 -96 82 -159 89 -30 3 -71 8 -91 10 -23 3 -40 0 -45 -7z"/>
<path d="M1956 1193 c-3 -4 -6 -21 -6 -40 0 -23 5 -33 15 -33 17 0 35 38 28
57 -5 14 -29 24 -37 16z"/>
<path d="M2648 1173 c-7 -30 3 -53 23 -53 20 0 36 40 24 59 -10 17 -42 13 -47
-6z"/>
<path d="M1821 1187 c-6 -6 -11 -24 -11 -39 0 -23 4 -28 25 -28 22 0 25 4 25
34 0 36 -19 53 -39 33z"/>
<path d="M1494 1176 c-10 -26 1 -51 21 -51 15 0 21 8 23 33 3 27 0 32 -18 32
-11 0 -23 -6 -26 -14z"/>
<path d="M1685 1131 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z"/>
<path d="M2076 1193 c-12 -12 -5 -23 14 -23 11 0 20 4 20 9 0 11 -26 22 -34
14z"/>
<path d="M2935 1171 c-8 -26 4 -51 26 -51 20 0 36 40 24 59 -10 18 -43 12 -50
-8z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
